.Section__header__container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.Section__header {
  height: 60px;
  width: 393.6px;
  border-bottom: 5px solid #f2f2f2;
}

.Section__header__text {
  font-size: 20px;
  color: #69a9de;
  font-stretch: normal;
  font-style: normal;
  line-height: 43px;
  letter-spacing: normal;
  text-transform: uppercase;
  text-align: center;
}
