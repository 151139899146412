body {
  background-color: #4c79bc;
}

@media screen and (min-width: 600px) {
  body {
    background-color: #f2f2f2;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
  }
}
