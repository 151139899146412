.DonorReceipt {
  padding: 30px;
  padding-bottom: 10px;
}

.DonorReceipt__logo {
  max-width: 150px;
}

.DonorReceipt__section {
  margin: 20px 0;
  padding: 0 5px;
}

.DonorReceipt__item {
  margin: 20px 0;
}

.DonorReceipt__actions {
  display: flex;
  justify-content: center;
}

.DonorReceipt__actions button {
  margin: 0 5px;
  flex: 1 1 auto;
}

.DonorReceipt td {
  padding-right: 10px;
}

.DonorReceipt td + td {
  font-weight: bold;
}

.DonorReceipt h3 {
  font-size: 22px;
  margin: 0 0 5px;
  font-weight: 800;
  text-transform: uppercase;
  color: #4c79bc;
}

.DonorReceipt h4 {
  font-size: 16px;
  margin: 0 0 5px;
  font-weight: 800;
}

.DonorReceipt h5 {
  font-size: 22px;
  line-height: 1.2;
  margin: 0 0 30px;
  font-weight: 800;
  text-transform: uppercase;
}

/* override blueprintjs dialog body */
.DonorReceipt__dialog .bp3-dialog-body {
  margin: 0 !important;
}
