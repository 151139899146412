.CreditCard {
  min-height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #4c79bc;
}

.CreditCard__left {
  flex: 0 0 60px;
  text-align: left;
}

.CreditCard__center {
  flex: 1;
}

.CreditCard__right {
  flex: 0 0 60px;
  text-align: right;
  font-weight: normal;
}

.CreditCard__holderName,
.CreditCard__last4 {
  text-transform: uppercase;
  font-weight: normal;
}

.CreditCard--payPal {
  min-height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #4c79bc;
}

.CreditCard--payPal p {
  margin: 10px 0 0 0;
  font-size: 14px;
}
