.Page,
.LeftAlign {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  min-height: 100vh;
}

@media screen and (min-width: 1100px) {
  .Page {
    margin-left: auto;
    margin-right: 4.166666666666667%; /*60px*/
    min-width: 600px;
    width: 41.6666666666667%; /*600px*/
  }

  .LeftAlign {
    margin-left: none;
    margin-left: 4.166666666666667%; /*60px*/
    min-width: 600px;
    width: 41.6666666666667%; /*600px*/
  }
}

.Page,
.LeftAlign form {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}
