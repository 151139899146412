.ActionButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: auto;
  margin: 0 !important;
  padding: 15px;
  outline: 0;
  border: none;
  color: #929292;
  background-color: #efefef;
  border-radius: 8px;
  transition: 0.2s;
  cursor: pointer;
}

.ActionButton--alt {
  color: #fff;
  background-color: #6caadc;
}

.ActionButton + .ActionButton {
  margin: 20px 0 0 !important;
}

.ActionButton:hover {
  box-shadow: 0 0 0 3px rgba(76, 121, 188, 0.45);
  text-decoration: none;
  color: inherit;
}

.ActionButton__title {
  margin: 0 0 7.5px;
  font-size: 20px;
  color: #4c79bc;
}

.ActionButton__text {
  margin: 0;
  font-size: 16px;
  line-height: 1.3;
  font-weight: 500;
  text-align: center;
  color: #4c79bc;
}

.ActionButton--alt .ActionButton__title {
  font-size: 18px;
  font-weight: 800;
  text-transform: uppercase;
}

.ActionButton--alt .ActionButton__title,
.ActionButton--alt .ActionButton__text {
  color: #fff;
}

.ActionButton__icon {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.ActionButton--active,
.ActionButton--active:hover,
.ActionButton--active.ActionButton--alt,
.ActionButton--active.ActionButton--alt:hover {
  color: #fff;
  background-color: #90d307;
  box-shadow: none;
}

.ActionButton--active > *,
.ActionButton--active:hover > * {
  color: #fff !important;
}
