/*
 * -------------------------------------------------------------------------------------------------
 * BASE
 * -------------------------------------------------------------------------------------------------
*/

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  padding: 0;
  line-height: 1.3;
  color: #444444;
  font-family: 'Gotham SSm A', 'Gotham SSm B';
  overflow-x: hidden;
}

.Page > .container,
.container-pad {
  background-color: #fff;
}

.bp3-overlay-backdrop {
  /* Allow scrolling with receipt popup dialog open */
  display: none !important;
}

h1 {
  margin: 0;
  font-size: 30px;
  font-weight: 600;
  line-height: 1.2;
  color: #4c79bc;
}

h2 {
  margin: 30px 0;
  font-size: 32px;
  font-weight: 900;
  color: #4c79bc;
}

h3 {
  margin: 30px 0 15px;
  color: #4c79bc;
}

img,
svg {
  display: block;
  max-width: 100%;
}

a {
  color: #4c79bc;
  text-decoration: none;
}

strong {
  font-weight: bold;
}

/*
 * -------------------------------------------------------------------------------------------------
 * HELPERS
 * -------------------------------------------------------------------------------------------------
*/

/* remove top  from first element and bottom margin from last element */

.module > *:first-child {
  margin-top: 0;
}

.module > *:last-child {
  margin-bottom: 0;
}

.full-height {
  min-height: 100vh;
}

.confirmation-header {
  font-size: 33px;
  text-align: center;
}

.confirmation-subheader {
  text-align: center;
  font-size: 20px;
  margin: 25px 0;
}

.thank-you-video {
  margin: 40px 0 5px 0;
}

.tac {
  text-align: center;
}

.container {
  padding-left: 20px;
  padding-right: 20px;
}

.container-pad {
  padding: 30px 20px;
}

@media (max-width: 500px) {
  .confirmation-header {
    font-size: 28px;
    text-align: center;
  }

  .thank-you-video {
    margin: 30px 0 5px 0;
  }
}

@media (min-width: 375px) {
  .container {
    padding-left: 30px;
    padding-right: 30px;
  }
  .container-pad {
    padding: 30px;
  }
}

/*
 * -------------------------------------------------------------------------------------------------
 * BUTTON
 * -------------------------------------------------------------------------------------------------
*/

.btn {
  outline: 0;
  border: none;
  height: 50px;
  padding-left: 30px;
  padding-right: 30px;
  margin-right: 15px;
  font-size: 18px;
  font-weight: 900;
  letter-spacing: -0.5px;
  line-height: 1;
  text-align: center;
  color: #fff;
  background-color: #ee3124;
  cursor: pointer;
  border-radius: 8px;
  text-transform: uppercase;
  transition: 0.2s;
}

.btn--solo {
  margin-right: 0;
}

/* button class on anchors need extras */

a.btn {
  display: inline-block;
  padding: 25px 30px;
  line-height: 0;
  text-decoration: none;
}

.btn:hover {
  color: #fff;
  background-color: #f35e47;
}

.btn--secondary {
  background-color: #4c79bc;
}

.btn--secondary:hover {
  background-color: #3f69a7;
}

button:disabled,
.btn:disabled {
  opacity: 0.5;
  pointer-events: none;
}

/*
 * -------------------------------------------------------------------------------------------------
 * SOCIAL SHARE BUTTON
 * -------------------------------------------------------------------------------------------------
*/

.socialButton {
  text-align: center;
  outline: none;
  border: none;
  background: none;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.socialButton + .socialButton {
  margin-top: 20px;
}

.socialButton__title {
  margin: 0 0 0 20px;
}

/*
 * -------------------------------------------------------------------------------------------------
 * THIRD PARTY OVERRIDES
 * -------------------------------------------------------------------------------------------------
*/

.bp3-dialog {
  margin: 30px 15px;
}

.bp3-dialog-header {
  background: #4c79bc;
}

.bp3-heading {
  color: #fff;
}

.bp3-dialog-header .bp3-icon {
  color: #fff;
}

input#dd-input#dd-input {
  background-color: #f2f2f2 !important;
  border: none;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 8px;
}

.Select-menu-outer {
  z-index: 9999 !important;
}

span.wrongcompany {
  display: block;
  font-size: 12px;
  margin-top: 3px;
}

span.wrongcompany a {
  color: #127cbd;
}

body.ie .secure-images {
  width: 100%;
}

/*
 * -------------------------------------------------------------------------------------------------
 * SHARED STYLES
 * -------------------------------------------------------------------------------------------------
*/

.same-line-input {
  display: flex;
}

.same-line-input > * + * {
  margin-left: 24px;
}
