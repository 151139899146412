.home-page-video iframe {
  text-align: center;
}

@media screen and (min-width: 600px) {
  .home-page-video iframe {
    width: 540px !important;
  }

  .mobile-image {
    display: none;
  }
}
