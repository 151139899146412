.Grid {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.Grid--col-2 .Grid__item-wrap {
  flex: 0 0 50%;
}

.Grid--col-3 .Grid__item-wrap {
  flex: 0 0 33.333%;
}

.Grid__item {
  height: 100%;
}

.Grid__item > * {
  height: 100%;
}
