.form-section {
  margin-bottom: 30px;
}

.input,
.input-group {
  position: relative;
  margin: 0 0 16px;
}

label {
  display: block;
  margin-bottom: 5px;
  color: #8d8c8f;
}

label:hover {
  cursor: pointer;
}

label.required:after {
  content: '*';
  position: relative;
  top: -1px;
  left: 2px;
  font-size: 13px;
  color: #ee3124;
}

input {
  outline: 0;
}

/*
  I'm adding `.hosted-field` as an extra classname here
  becasue I want it styled the same way as text inputs
*/

.hosted-field {
  height: 42px;
}

.text-input,
.hosted-field {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 8px;
  background-color: #ffffff;
  /*#efefef*/
}

.text-input:hover,
.hosted-field:hover {
  box-shadow: inset 0 0 0 2px #6baadc;
}

.text-input:focus,
.hosted-field:focus {
  box-shadow: inset 0 0 0 2px #4c79bc;
}

.text-input::placeholder,
.hosted-field::placeholder {
  color: #929292;
}

.text-input--error,
.text-input--error:hover,
.hosted-field--error,
.hosted-field--error:hover {
  box-shadow: inset 0 0 0 2px #ee3124;
}

#OtherAmount.text-input {
  background-color: #f2f2f2;
}

.input-feedback {
  margin-top: 5px;
  font-size: 14px;
  color: #ee3124;
}

.radio-button-label {
  height: 50px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.5px;
  line-height: 1.21;
  text-align: center;
  color: #777777;
  background-color: #ffffff;
  cursor: pointer;
  border: solid 1px #dddddd;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.radio-button-input a.radio-button-label {
  color: #6caadc;
  text-decoration: none;
}

.radio-button-label--selected {
  color: #fff !important;
  background-color: #4b79bc !important;
}

.radio-button-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.radio-button-group .radio-button-input {
  flex: 0 0 50%;
}

.radio-button-group .radio-button-input .radio-button-label {
  margin: 5px;
}

.radio-button-group .radio-button-input--fill {
  flex: 0 0 100%;
  margin-bottom: 10px;
}

.radio-button-input-small {
  flex: 1 0 30%;
  justify-content: space-evenly;
  margin: 5px 0;
}

.input-group-message {
  margin: 0;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.43;
  color: #626165;
  text-align: center;
}

/* ---------------------------------------------------------------
 * blueprintjs style overrides for select input
 * --------------------------------------------------------------- */

.bp3-html-select {
  border-radius: 8px !important;

  background-color: #ffffff !important;
}

.bp3-html-select select {
  border-radius: 8px !important;
  border: solid 1px #dddddd !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #999999 !important;
  background-color: transparent !important;
}

.bp3-html-select:hover select {
  box-shadow: inset 0 0 0 2px #6baadc !important;
}

.bp3-html-select select:focus {
  box-shadow: inset 0 0 0 2px #6baadc !important;
}

.bp3-control input:checked ~ .bp3-control-indicator {
  background-color: #90d307 !important;
}
